/* open-sans-400 - Charsets: latin */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/latin/open-sans-v40-latin-regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* open-sans-400 - Charsets: latin-extended */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/latin-ext/open-sans-v40-latin-ext-regular.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* open-sans-400 - Charsets: cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/cyrillic/open-sans-v40-cyrillic-regular.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* open-sans-600 - Charsets: latin */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/latin/open-sans-v40-latin-600.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* open-sans-600 - Charsets: latin-extended */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/latin-ext/open-sans-v40-latin-ext-600.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* open-sans-600 - Charsets: cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./resources/fonts/cyrillic/open-sans-v40-cyrillic-600.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* open-sans-700 - Charsets: latin */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/fonts/latin/open-sans-v40-latin-700.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* open-sans-700 - Charsets: latin-extended */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/fonts/latin-ext/open-sans-v40-latin-ext-700.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* open-sans-700 - Charsets: cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/fonts/cyrillic/open-sans-v40-cyrillic-700.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* roboto-100 - Charsets: latin */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    src: url('./resources/fonts/latin/roboto-v30-latin-100.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-100 - Charsets: latin-extended */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    src: url('./resources/fonts/latin-ext/roboto-v30-latin-ext-100.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* roboto-100 - Charsets: cyrillic */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    src: url('./resources/fonts/cyrillic/roboto-v30-cyrillic-100.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* roboto-400 - Charsets: latin */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/latin/roboto-v30-latin-regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-400 - Charsets: latin-extended */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/latin-ext/roboto-v30-latin-ext-regular.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* roboto-400 - Charsets: cyrillic */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/cyrillic/roboto-v30-cyrillic-regular.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* roboto-500 - Charsets: latin */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/latin/roboto-v30-latin-500.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-500 - Charsets: latin-extended */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/latin-ext/roboto-v30-latin-ext-500.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* roboto-500 - Charsets: cyrillic */
@font-face {
    font-display: swap;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/cyrillic/roboto-v30-cyrillic-500.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
